import * as React from "react";


import Paper from "@mui/material/Paper";

import Grid from "@mui/material/Grid";
import { Typography} from "@mui/material";


import AttachmentsTable from "./AttachmentsTable";
import dayjs from "dayjs";


const DetailMail = ({ ClickedRowId,data }) => {

    return (
        <Paper sx={{ flex: 1, ml: 7, width: "90%", pr: 10, backgroundColor: '#9a9b9966' }}>

            <Grid container spacing={1} ml={1} mt={1}>
                <Grid><Typography
                className="info"
                variant="body1"
                color="alfaNordic.main"
                ml={3}
                >{`Día: ${dayjs(ClickedRowId.fecha_registro).format("DD/MM/YYYY") }`}</Typography></Grid>
                
               
               
            </Grid>
            
            



            
            <Grid container spacing={1} ml={4} mt={1}>
                <Grid><Typography variant="subtitle1" color="alfaNordic.maintextColor" >
                    Cuerpo:
                    <Typography variant="body2" color="alfaNordic.maintextColor" >
                        {ClickedRowId.email_Body}
                    </Typography>
                </Typography></Grid>


            </Grid>


           {/*<AttachmentsTable id={ClickedRowId.noLinea} data={data}/>}*/}

        </Paper>
    );
};

export default DetailMail;
