import React from "react";
import Widget from "../../components/widget/Widget";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";



const Widgets = ({ DataTT, DataIT, DataGan_V, DataGan_Q, DataRe_V, DataRe_Q, DataEnT, DataPCli, setFilterModel }) => {

    const HandleOnClickWidget = () => {
        
            setFilterModel({ items: [{ columnField: 'Requiere Acción', operatorValue: 'is', value: 'true' }] })
            sessionStorage.setItem('filters', JSON.stringify({ items: [{ columnField: 'Requiere Acción', operatorValue: 'is', value: 'true' }] })); 
    }
 
  return (
   
    <Box>
    <Grid container spacing={2} justifyContent="flex-end">
      <Grid item xs={6}  lg={2}>
        <Widget

          type="TOTAL TRAMITADOS"
          Value={DataTT}
          color={"#4194cb"}
          isMoney={false}
        />
      </Grid>
      <Grid item xs={6}  lg={2}>
        <Widget
          type="IMPORTE TOTAL"
          Separator="-"
          Value={DataIT}
          color={"#d3d3d3"}
          isMoney={true}
        />
      </Grid>
      <Grid item xs={6} lg={2}>
        <Widget
          type="GANADOS"
          Quantity={DataGan_Q}
          Separator="-"
          Value={DataGan_V}
          color={"#00c800"}
          isMoney={true}
        />
      </Grid>
      <Grid item xs={6}  lg={2}>
        <Widget
          type="REBAJADOS"
          Quantity={DataRe_Q}
          Separator="-"
          Value={DataRe_V}
          color={"#00c800"}
          isMoney={true}
        />
      </Grid>
      <Grid item xs={6}  lg={2}>
        <Widget
          type="EN TRÁMITE"
          Value={DataEnT}
          color={"#fc9303"}
          isMoney={false}
        />
      </Grid>
      <Grid item xs={6}  lg={2}>
        <Widget
          type="PENDIENTES CLIENTE"
                      onClickWidget={HandleOnClickWidget}   
          Value={DataPCli}
          color={"#fd4f41"}
          isMoney={false}
          
        />
        
      </Grid>
    </Grid>
  </Box>
   
  );
};

export default Widgets;