import Home from './pages/home/Home';
import * as msal from "@azure/msal-browser";
import ThemeProvider from './utils/themeIndex';

import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import { useEffect } from 'react'
import { LicenseInfo } from '@mui/x-license-pro';




function App() {

  LicenseInfo.setLicenseKey("68cbd4cb8f5a993f1eb837ba8cc682b3Tz01Mzk2OCxFPTE2OTk1MzE4NTAxNzcsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");
  return (
    
    
     <ThemeProvider>
     <BrowserRouter>
          <Routes>
            <Route path='/'>
                
                <Route index element ={<Home />}></Route>

            </Route>
           
            
            
          </Routes>
     </BrowserRouter>
     </ThemeProvider>
    
  );

}
export default App;
