import React from "react";
import UserTopInfo from "../../components/TopInfo/UserTopInfo";
import Navbar from "../../components/sidebar/Navbar";
import Widgets from "../../components/widget/Widgets";
import CustomerTable from "../../components/table/CustomerTable";
import PieCharts from "../../components/PieChart/PieCharts";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { Container, Typography } from "@mui/material";
import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useState, useEffect, useRef } from "react";
import { loginRequest } from "../../utils/authConfig";
import dayjs from "dayjs";
import axios from "axios";
import { ENDPOINTS, createAPIEndpoint } from "../../components/API/Api";
import BounceLoader from "react-spinners/BounceLoader";
import LinearProgress from "@mui/material/LinearProgress";

const Home = () => {
  dayjs.locale("es");
  //const [filteredData, setFilteredData] = useState([]);
  const toDateFilter = new Date();
  const fromDateFilter = new Date();
  toDateFilter.getDate();
  fromDateFilter.setFullYear(fromDateFilter.getFullYear() - 1);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const [listaExpedientes, setListaExpedientes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [widgetsData, setWidgetsData] = useState({});
  const [personName, setPersonName] = useState([]);
  const [newCustomersObj, setNewCustomersObj] = useState([]);
  const [customersObj, setCustomersObj] = useState([]);
  const { instance, inProgress, accounts } = useMsal();
  const [apiData, setApiData] = useState("");
  const [userName, setUserName] = useState("");
  const [comercialName, setComercialName] = useState("");
  const [dataToShow, setDataToShow] = useState([]);
  const [token, SetToken] = useState("");
  const [fromValue, setFromValue] = React.useState(dayjs(fromDateFilter));
  const [toValue, setToValue] = React.useState(dayjs(toDateFilter));
  const [customers, setCustomers] = useState([]);
  const [customersNo, setCustomersNo] = useState([]);
  const [customersName, setCustomersName] = useState([]);
  const [filterModel, setFilterModel] = React.useState({
    items: [{ columnField: "", operatorValue: "", value: "" }],
  });
  const refContainer = useRef(null);
  const [storageFilters, setStorageFilters] = useState([]);
  const [error, setError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const keywords = ["Petición", "Documento", "Solicitud"];

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 500,
      },
    },
    MenuListProps: {
      sx: {
        //change the background of the customer secelt
        backgroundColor: "alfaNordic.menus",
      },
    },
  };

  const handleFromDate = () => {
    if (JSON.parse(sessionStorage.getItem("fromDate")) !== null) {
      return dayjs(JSON.parse(sessionStorage.getItem("fromDate"))).format(
        "YYYY-MM-DD"
      );
    } else {
      return dayjs(fromValue).format("YYYY-MM-DD");
    }
  };
  const handleToDate = () => {
    if (JSON.parse(sessionStorage.getItem("toDate")) !== null) {
      return dayjs(JSON.parse(sessionStorage.getItem("toDate"))).format(
        "YYYY-MM-DD"
      );
    } else {
      return dayjs(toValue).format("YYYY-MM-DD");
    }
  };

  //Event call to get and update the widgets value
  const getWidgetsValues = (data) => {
    let returnValue = {
      importeTotal: 0,
      totalTram: 0,
      ganadosCount: 0,
      ganadosValue: 0,
      rebajadosCount: 0,
      rebajadosValue: 0,
      enTramite: 0,
      pendienteCliente: 0,

      graphByType: { series: [], labels: [], colors: [] },
      graphByMotv: { series: [], labels: [], colors: [] },
      graphByGrav: {
        series: [],
        labels: [],
        colors:
          [] /*[({ value }) => { if (value === "Muy Grave") return "#f44336"; if (value === "Grave") return "#ff9800"; if (value === "Leve") return "#ffeb3b" }]*/,
      },
    };
    const graphList = [
      { label: "tipo_expediente", item: "graphByType" },
      { label: "motivo_sancion", item: "graphByMotv" },
      { label: "gravedad", item: "graphByGrav" },
    ];

    data.map((dataItem) => {
      //Graphs
      for (let a = 0; a < graphList.length; a++) {
        let i = returnValue[graphList[a].item].labels.findIndex(
          (f) => dataItem[graphList[a].label] === f
        );
        if (i === -1) {
          returnValue[graphList[a].item].series.push(1);
          returnValue[graphList[a].item].labels.push(
            dataItem[graphList[a].label]
          );
          if (graphList[a].item === "graphByGrav") {
            if (dataItem[graphList[a].label] === "Muy Grave") {
              returnValue[graphList[a].item].colors.push("#f44336");
            } else if (dataItem[graphList[a].label] === "Grave") {
              returnValue[graphList[a].item].colors.push("#ff9800");
            } else {
              returnValue[graphList[a].item].colors.push("#ffeb3b");
            }
          } else if (graphList[a].item === "graphByType") {
            returnValue[graphList[a].item].colors.push("#4CAF50");
          } else if (graphList[a].item === "graphByMotv") {
            returnValue[graphList[a].item].colors.push("#008FFB");
          }
        } else {
          returnValue[graphList[a].item].series[i] =
            returnValue[graphList[a].item].series[i] + 1;
        }
      }
      returnValue.importeTotal = Math.round(
        returnValue.importeTotal + dataItem.importe_inicial
      );

      if (
        keywords.some((keyword) => dataItem.estado_expediente.includes(keyword))
      ) {
        returnValue.pendienteCliente++;
      }
      if (dataItem.estado === "FINALIZADO" || dataItem.estado === "PAGADO") {
      } else if (dataItem.estado === "SOBRESEÍDO") {
        returnValue.ganadosCount++;
        returnValue.ganadosValue = Math.round(
          returnValue.ganadosValue + dataItem.importe_inicial
        );
      } else if (
        dataItem.estado === "REBAJADO-PAGADO" ||
        dataItem.estado === "REBAJADO"
      ) {
        returnValue.rebajadosCount++;
        returnValue.rebajadosValue = Math.round(
          returnValue.rebajadosValue + dataItem.importe_inicial
        );
      } else if (dataItem.estado === "EN PROCESO") {
        returnValue.enTramite++;
      }
      returnValue.totalTram++;
    });

    return returnValue;
  };

  useEffect(() => {
    setLoading(true);

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    if (apiData === "" && inProgress === InteractionStatus.None) {
      instance

        .acquireTokenSilent(request)
        .then((tokenResponse) => {
          SetToken(tokenResponse.accessToken);

          setUserName(tokenResponse.idTokenClaims.name);
          setComercialName(tokenResponse.idTokenClaims.name);

          const config = {
            headers: { Authorization: `Bearer ${tokenResponse.accessToken}` },
          };

          createAPIEndpoint(ENDPOINTS.CustomerList)
            .fetchCustomers(config)
            .then((customerResponse) => {
              setApiData(customerResponse);

              let CustomerArray = customerResponse.data.customers;

              setIsAdmin(customerResponse.data.isAdmin);

              if (customerResponse.data.isAdmin) {
                setCustomers(CustomerArray);

                if (
                  JSON.parse(sessionStorage.getItem("customersSelected")) ===
                    null ||
                  JSON.parse(sessionStorage.getItem("customersSelected"))[0]
                    .name === "NO HAY CLIENTES SELECCIONADOS"
                ) {
                  setPersonName(["NO HAY CLIENTES SELECCIONADOS"]);
                } else {
                  if (
                    JSON.parse(sessionStorage.getItem("customersSelected"))
                      .length >= 1 &&
                    JSON.parse(sessionStorage.getItem("customersSelected"))[0]
                      .name !== "NO HAY CLIENTES SELECCIONADOS"
                  ) {
                    setPersonName(
                      JSON.parse(sessionStorage.getItem("customersSelected"))
                        .filter(
                          (value) =>
                            value.name !== "NO HAY CLIENTES SELECCIONADOS"
                        )
                        .map((value) => value.name)
                    );
                    setNewCustomersObj(
                      JSON.parse(sessionStorage.getItem("customersSelected"))
                    );
                  } else {
                    setPersonName(["NO HAY CLIENTES SELECCIONADOS"]);
                  }
                }
              } else {
                console.log(CustomerArray);
                setCustomers(CustomerArray);
                if (
                  JSON.parse(sessionStorage.getItem("customersSelected")) ===
                  null
                ) {
                  setPersonName(CustomerArray.map((name) => name.name));
                } else {
                  if (
                    JSON.parse(sessionStorage.getItem("customersSelected"))
                      .length >= 1 &&
                    JSON.parse(sessionStorage.getItem("customersSelected"))[0]
                      .name !== "NO HAY CLIENTES SELECCIONADOS"
                  ) {
                    setPersonName(
                      JSON.parse(sessionStorage.getItem("customersSelected"))
                        .filter(
                          (value) =>
                            value.name !== "NO HAY CLIENTES SELECCIONADOS"
                        )
                        .map((value) => value.name)
                    );
                  } else {
                    setPersonName(["NO HAY CLIENTES SELECCIONADOS"]);
                  }
                }
                createAPIEndpoint(ENDPOINTS.Files)
                  .post(
                    {
                      fromDate: handleFromDate(),
                      toDate: handleToDate(),

                      // customers: newCustomersObj.map((no) => no.no)
                      customers: CustomerArray.map((cust) => cust.no),
                    },
                    config
                  )
                  .then((respuesta) => {
                    setListaExpedientes(respuesta.data.value);

                    setWidgetsData(getWidgetsValues(respuesta.data.value));

                    setDataToShow(respuesta.data.value);
                    setIsFetchingData(false);

                    //Cierre del then Files
                  })

                  .catch((e) => {
                    setIsFetchingData(false);
                    setError(e.message);
                  });
              }
            })
            .finally(() => setLoading(false), setIsFetchingData(false))
            .catch((e) => {
              setLoading(false);
              setError(e.message);
            });

          //cierre del then del token response
        })
        //catch de token
        .catch((e) => {
          //setError(e.message)
          instance.acquireTokenRedirect(request).then((tokenResponse) => {
            SetToken(tokenResponse.accessToken);
          });
        });
    }
  }, [instance, accounts, inProgress]);

  useEffect(() => {
    setIsFetchingData(true);

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data

    instance

      .acquireTokenSilent(request)
      .then((tokenResponse) => {
        SetToken(tokenResponse.accessToken);

        setUserName(tokenResponse.idTokenClaims.name);
        setComercialName(tokenResponse.idTokenClaims.name);

        const config = {
          headers: { Authorization: `Bearer ${tokenResponse.accessToken}` },
        };

        if (
          JSON.parse(sessionStorage.getItem("customersSelected")) !== null &&
          newCustomersObj !== null
        ) {
          createAPIEndpoint(ENDPOINTS.Files)
            .post(
              {
                fromDate: handleFromDate(),
                toDate: handleToDate(),

                // customers: newCustomersObj.map((no) => no.no)
                customers: JSON.parse(
                  sessionStorage.getItem("customersSelected")
                ).map((cust) => cust.no),
              },
              config
            )
            .then((respuesta) => {
              setListaExpedientes(respuesta.data.value);

              setWidgetsData(getWidgetsValues(respuesta.data.value));

              setDataToShow(respuesta.data.value);
              setIsFetchingData(false);

              //Cierre del then Files
            })

            .catch((e) => {
              setIsFetchingData(false);
              setError(e.message);
            });
        } else {
          setListaExpedientes([]);

          setWidgetsData([]);

          setDataToShow([]);
          setIsFetchingData(false);
        }
      })

      //catch de token
      .catch((e) => {
        //setError(e.message)
        instance.acquireTokenRedirect(request).then((tokenResponse) => {
          SetToken(tokenResponse.accessToken);
        });
      });
  }, [newCustomersObj, fromValue, toValue]);

  return (
    <>
      {error && (
        <Container
          maxWidth={false}
          sx={{
            backgroundColor: "#121212",
            height: "100vh",
            flexGrow: 1,
          }}
        >
          <Grid container justifyContent="center">
            <Grid item xs={12} mt="20vh" ml="80vh">
              <Avatar
                alt=""
                src={require("../../utils/Logo.png")}
                variant="rounded"
                sx={{
                  width: { xs: 250, md: 250, lg: 350 },
                  height: { xs: 250, md: 250, lg: 350 },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                component={"span"}
                variant="h6"
                mt="5vh"
                ml="80vh"
                aligntext="center"
                color="alfaNordic.main"
              >
                ERROR: {error}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      )}
      {/*ShowUp when a user is Loged IN*/}
      <AuthenticatedTemplate>
        {!loading && !error && (
          <Container
            className="home"
            maxWidth={false}
            sx={{ backgroundColor: "#121212" }}
            disableGutters
          >
            <Navbar
              setFilterModel={setFilterModel}
              customers={customers}
              personName={personName}
              fromDate={fromValue}
              toDate={toValue}
              isAdmin={isAdmin}
              isFetchingData={isFetchingData}
            />
            <Container maxWidth={false}>
              {/*TopSide Home Page, user info and date Filter*/}
              <UserTopInfo
                personName={personName}
                setPersonName={setPersonName}
                MenuProps={MenuProps}
                customers={customers.map((name) => name.name)}
                CustomersObj={customersObj}
                setCustomersObj={setCustomersObj}
                customersObj={customers}
                newCustomersObj={newCustomersObj}
                setNewCustomersObj={setNewCustomersObj}
                userName={userName}
                comercialName={comercialName}
                fromValue={fromValue}
                setFromValue={setFromValue}
                toValue={toValue}
                setToValue={setToValue}
                setDataToShow={setDataToShow}
                setApiData={setApiData}
                setWidgetsData={setWidgetsData}
                getWidgetsValues={getWidgetsValues}
                listaExpedientes={listaExpedientes}
                isFetchingData={isFetchingData}
              />

              {/*Widgets Data*/}

              <Box mt={3}>
                {!personName.length > 0 ||
                  (personName !== "NO HAY CLIENTES SELECCIONADOS" && (
                    <Widgets
                      DataTT={widgetsData.totalTram}
                      DataIT={widgetsData.importeTotal}
                      DataGan_Q={widgetsData.ganadosCount}
                      DataGan_V={widgetsData.ganadosValue}
                      DataRe_Q={widgetsData.rebajadosCount}
                      DataRe_V={widgetsData.rebajadosValue}
                      DataEnT={widgetsData.enTramite}
                      DataPCli={widgetsData.pendienteCliente}
                      setFilterModel={setFilterModel}
                    />
                  ))}
              </Box>
              <Box mt={1}>
                {/*Pie Data*/}
                {!personName.length > 0 ||
                  (personName !== "NO HAY CLIENTES SELECCIONADOS" && (
                    <PieCharts
                      DataTipo={widgetsData.graphByType}
                      DataMot={widgetsData.graphByMotv}
                      DataGra={widgetsData.graphByGrav}
                    />
                  ))}
              </Box>
              {/*Main table*/}
              <Box className="dataGrid" sx={{ mt: 10 }}>
                <CustomerTable
                  rowdata={dataToShow}
                  filteredData={widgetsData}
                  keywords={keywords}
                  setFilteredData={setWidgetsData}
                  onFilterChange={getWidgetsValues}
                  loading={loading}
                  filterModel={filterModel}
                  setFilterModel={setFilterModel}
                  error={error}
                  setError={setError}
                />
              </Box>
            </Container>
          </Container>
        )}
        {loading && (
          <Container
            maxWidth={false}
            sx={{
              backgroundColor: "#121212",
              height: "100vh",
              flexGrow: 1,
            }}
          >
            <Grid container justifyContent="center">
              <Grid item xs={0} mt="40vh">
                <BounceLoader color="#74F9FF" loading={loading} size={150} />
                <Typography
                  component={"span"}
                  variant="h6"
                  m="2.1vh"
                  aligntext="center"
                  color="alfaNordic.main"
                >
                  CARGANDO...
                </Typography>
              </Grid>
            </Grid>
          </Container>
        )}
      </AuthenticatedTemplate>

      {/*ShowUp when a user is not Loged IN*/}
      <UnauthenticatedTemplate>
        <Container
          className="home"
          maxWidth={false}
          sx={{ backgroundColor: "#121212" }}
        >
          <Grid
            container
            spacing={0}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <Grid item xs={3}>
              <Avatar
                alt=""
                src={require("../../utils/Logo.png")}
                variant="rounded"
                sx={{
                  width: { xs: 250, md: 250, lg: 350 },
                  height: { xs: 250, md: 250, lg: 350 },
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </UnauthenticatedTemplate>
    </>
  );
};
export default Home;
