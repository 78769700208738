
import { Card, CardContent, Typography,Button,IconButton } from "@mui/material";
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import Grid from "@mui/material/Grid";


const Widget = ({ type, Quantity, Separator, Value, color, isMoney,onClickWidget }) => {

  return (
   
    
          
          <Card sx={{ border: 0, borderColor: "alfaNordic.main", backgroundColor: '#9a9b9966' }}>
         
          <CardContent onClick={onClickWidget}>


              <Typography sx={{ typography: { xs: "subtitle1",sm:"h4", lg: "h4" } }} component={'span'}  color={color} textAlign="center">
                  <Grid container
                      spacing={1}

                      justifyContent="center"
                      style={{ minHeight: "auto" }}>
                      <Grid item xs={0}>{type === "PENDIENTES CLIENTE" ? <ReportProblemRoundedIcon className='icon' color="red" sx={{ width: 20, height: 20 }} /> : ""}</Grid>
                      <Grid item xs={0}>{isMoney && type !== "IMPORTE TOTAL"
                          ? Quantity +
                          Separator +
                          Number(Value).toLocaleString("sp-SP", {
                                
                            maximumFractionDigits: 0,
                        }) + " €"
                          : type === "IMPORTE TOTAL"
                              ?  Number(Value).toLocaleString("sp-SP", {
                                
                                maximumFractionDigits: 0,
                            }) + " €"
                              : Value}</Grid>

                  </Grid>


              </Typography>
              <Typography sx={{ typography: { xs: "smallWidget", sm: "h6", xl: "h6" } }}   color="widget.main" textAlign="center">
                  {" "}
                  {type}
              </Typography>

          </CardContent>
          </Card>     
   
         
   
        );
    
};

export default Widget;
