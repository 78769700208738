import * as msal from "@azure/msal-browser";
import {useEffect } from "react";
import { EventType } from "@azure/msal-browser";
import {
  useMsal
} from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";



export const msalConfig = {
    auth: {
      clientId: "6ca3cdb2-533b-4094-bbc8-2feefba0e493",
      authority: 'https://alfanordicsl2018web.b2clogin.com/alfanordicsl2018web.onmicrosoft.com/B2C_1_signIn',
      knownAuthorities: ['alfanordicsl2018web.b2clogin.com'],
      redirectUri: process.env.B2C_REDIRECT_URI,
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  };
  export const loginRequest = {
      scopes: ["https://alfanordicsl2018web.onmicrosoft.com/6b423126-6eab-4bb8-89c9-25f482c2a768/portal.use"]
   };

  export const msalInstance = new PublicClientApplication(msalConfig);
 
  export function EventExample() {
      const { instance } = useMsal();
  
      useEffect(() => {
          // This will be run on component mount
          const callbackId = instance.addEventCallback((message) => {
              // This will be run every time an event is emitted after registering this callback
              if (message.eventType === EventType.LOGIN_SUCCESS) {
                  const result = message.payload;    
                  // Do something with the result
              }
          });
  
          return () => {
              // This will be run on component unmount
              if (callbackId) {
                  instance.removeEventCallback(callbackId);
              }
          }
          
      }, []);
  }