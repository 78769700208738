import * as React from "react";


import Paper from "@mui/material/Paper";

import Grid from "@mui/material/Grid";
import { Typography} from "@mui/material";


import ProcedureTable from "./ProcedureTable";
import dayjs from "dayjs";

let ClickedRowId = [
  {
    cif: "F64497191",
    cobertura: "0%",
    cod_Cliente: "C0032",
    cod_Titular: "C0032",
    conductor: "ALIN RIMBA",
    estado: "PAGADO",
    estado_expediente: "Pagado",
    expediente_relacionado: "",
    fecha_alta_expediente: "2019-02-20T00:00:00",
    fecha_sancion: "2018-05-15T00:00:00",
    fin_periodo_sancionado: "0001-01-01",
    gravedad: "Leve",
    hora_sancion: "15:30:00",
    horas_sancion: "",
    importe_actual: 90.44,
    importe_inicial: 90.44,
    inicio_periodo_sancionado: "0001-01-01",
    matricula: "2234FNK",
    motivo_sancion: "No pagar peaje",
    no_Alfa_Nordic: "EI18-0003",
    no_Contrato: "",
    no_Expediente_actual: "4408090225547",
    no_Expediente_via_admin: "4408090225547",
    no_Inspeccion: "",
    no_Liquidacion: "",
    nombre_cliente: "TRANSPORTES BARA S.C.C.L.",
    nombre_titular: "TRANSPORTES BARA S.C.C.L.",
    organismo_actual: "DART CHARGE",
    organismo_inicial: "DART CHARGE",
    puntos: 0,
    rebajada: false,
    remolque: "",
    sobreseida: false,
    tipo_expediente: "INTERNACIONAL",
    tipo_titular_sancion: "Cliente contrato",
    usuario_alta_expediente: "SCANALETA",
    via_admin_ejec: "Administrativa",
  },
];

const DetailTable = ({ ClickedRowId,setError,error }) => {
 
   console.log(ClickedRowId)
  
  return (
    <Paper sx={{ flex: 1, mx: "auto", width: "90%", p: 3, mt: 2,mb:2,backgroundColor:'#9a9b9966'}}>
      
        <Typography
          className="info"
          variant="h4"
          color="alfaNordic.main"
          ml={3}
        >{`Procedimientos del Expediente: ${ClickedRowId.no_Alfa_Nordic}`}</Typography>
         

        
          
            <Grid container spacing={1} justifyContent="space-evenly"  mt={2}>
              <Grid><Typography variant="subtitle1" color="alfaNordic.maintextColor" >
              Hora denuncia
              <Typography variant="body2" color="alfaNordic.maintextColor" >
                {ClickedRowId.hora_sancion}
              </Typography>
            </Typography></Grid>

              <Grid><Typography variant="subtitle1" color="alfaNordic.maintextColor">
              Cobertura
              <Typography variant="body2" color="alfaNordic.maintextColor">
                {ClickedRowId.cobertura}
              </Typography>
            </Typography></Grid>
            
            <Grid><Typography variant="subtitle1" color="alfaNordic.maintextColor">
              Conductor
              <Typography variant="body2" color="alfaNordic.maintextColor">
                {ClickedRowId.conductor}
              </Typography>
            </Typography></Grid>

            <Grid><Typography variant="subtitle1" color="alfaNordic.maintextColor">
              Puntos
              <Typography variant="body2" color="alfaNordic.maintextColor">
                {ClickedRowId.puntos}
              </Typography>
            </Typography></Grid>
            
            <Grid><Typography variant="subtitle1" color="alfaNordic.maintextColor">
              Importe Inicial
              <Typography variant="body2" color="alfaNordic.maintextColor">
                {ClickedRowId.importe_inicial}€
              </Typography>
            </Typography></Grid>

            
            <Grid><Typography variant="subtitle1" color="alfaNordic.maintextColor">
                Importe Actual
                <Typography variant="body2" color="alfaNordic.maintextColor">
                  {ClickedRowId.importe_actual}€
                </Typography>
              </Typography></Grid>
            
          
          
            <Grid><Typography variant="subtitle1" color="alfaNordic.maintextColor">
              Periodo Sancionado
              <Typography variant="body2" color="alfaNordic.maintextColor">
                
              {ClickedRowId.inicio_periodo_sancionado !== "0001-01-01T00:00:00"?dayjs(ClickedRowId.inicio_periodo_sancionado).format("DD/MM/YYYY"):""} - {ClickedRowId.fin_periodo_sancionado !== "0001-01-01T00:00:00"?dayjs(ClickedRowId.fin_periodo_sancionado).format("DD/MM/YYYY"):""}
              </Typography>
            </Typography></Grid>

            <Grid><Typography variant="subtitle1" color="alfaNordic.maintextColor">
              Horas Sanción
              <Typography variant="body2" color="alfaNordic.maintextColor">
                {ClickedRowId.horas_sancion}
              </Typography>
            </Typography></Grid>
            
            <Grid><Typography variant="subtitle1" color="alfaNordic.maintextColor">
              Remolque
              <Typography variant="body2" color="alfaNordic.maintextColor">
                {ClickedRowId.remolque}
              </Typography>
            </Typography></Grid>
            <Grid><Typography variant="subtitle1" color="alfaNordic.maintextColor">
                  Observaciones
                  <Typography variant="body2" color="alfaNordic.maintextColor">
                      {ClickedRowId.observaciones}
                  </Typography>
              </Typography></Grid>
            
            </Grid>
          
        
          <ProcedureTable id={ClickedRowId.no_Alfa_Nordic} c_cliente={ClickedRowId.cod_Cliente} mailToAdress={ClickedRowId.email} 
                              setError={setError}  error={error}/>
     
    </Paper>
  );
};

export default DetailTable;
