import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MailIcon from "@mui/icons-material/Mail";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import AtachFiles from "./AtachFiles";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import {
  DataGridPro,
  
  GridToolbarQuickFilter,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from "@mui/x-data-grid-pro";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useState, useEffect } from "react";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { ENDPOINTS, createAPIEndpoint } from "../API/Api";
import { loginRequest } from "../../utils/authConfig";
import {
    useMsal,
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import dayjs from "dayjs";
import DetailMail from './DetailMail';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Mail(dataAsunto,mailTo,noAlfaNordic,noProcedimiento,noLinea) {
    const [open, setOpen] = useState(false);
    const [canCallApi, setCanCallApi] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [MailContent, setMailContent] = useState({});
  const [to, setTo] = useState(".");
  const [CusomterSubject, setCustomerSubject] = useState("");
  const [MailBody, setMailBody] = useState({});
  const [mailAttachments, setMailAttachments] = useState([]);
    const [openPopUp, setOpenPopup] = React.useState(false);
    const [openPopUpErrorRequest, setOpenPopupErrorRequest] = React.useState(false);
    const [openPopUpErrorSend, setOpenPopupErrorSend] = React.useState(false);
    const [token, SetToken] = useState("");
    const { instance, inProgress, accounts } = useMsal();
    const [mailRowsHis, setMailRowsHis] = useState();
    const [error, setError] = useState();

    const handleClickOpen = () => {

        setOpen(true);
        setCanCallApi(true);
  };

    
  const handleClosePopUp = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenPopup(false);
    setOpenPopupErrorRequest(false)
    setOpenPopupErrorSend(false)
  };

  const handleClose = () => {
    setOpen(false);
  };
    const columns = [
       
      {
        field: "no_Linea",
        headerName: "No. Linea",
        type:"number",
        width: 100,
        headerClassName: "header",
      },
        {
            field: "fecha_registro",
            headerName: "Fecha Registro",
            width: 120,
            type: "date",
            headerClassName: "header",
            valueFormatter: params =>
                dayjs(params?.value).format("DD/MM/YYYY"),
        },
        {
            field: "descripcion",
            headerName: "Descripción",
            width: 400,
            headerClassName: "header",
        },
        {
            field: "observaciones",
            headerName: "Asunto",
            width: 350,
            headerClassName: "header",
        },
        /*{
            field: "Adjuntos",
            headerName: "Adjuntos",
            width: 500,
            renderCell: (params) => (
                <Button onClick={() => window.open(params.value)}>
                    <Typography
                        color="alfaNordic.main"
                        sx={{ textDecoration: "underline" }}
                    >
                        {params.value}
                    </Typography>
                </Button>
            ),
            headerClassName: "header",
        },*/
    ];

    const tempRows = [
      {
        no_Linea:1,
        fecha_registro: 1,
        descripcion: "BOLETIN DE DENUNCIA",
        observaciones: "01/01/2020",
        
      },
      {
        no_Linea:2,
        fecha_registro: 1,
        descripcion: "BOLETIN DE DENUNCIA",
        observaciones: "01/01/2020",
      },
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Grid container
                    spacing={3}

                    justifyContent="space-between"
                    style={{ minHeight: "auto" }}>
                    <Grid item xs={0}><GridToolbarColumnsButton color="alfaNordic" />
                        <GridToolbarFilterButton color="alfaNordic" />
                        <GridToolbarDensitySelector color="alfaNordic" />
                    </Grid>


                    <Grid item xs={0}><GridToolbarQuickFilter /></Grid>

                </Grid>



            </GridToolbarContainer>
        );
    };

    const handleCloseMailScreen = () => {
      setOpen(false);
      canCallApi(false);
    }
    
    
  
    useEffect(() => {
       
      if (open && canCallApi) {
        
          const request = {
              ...loginRequest,
              account: accounts[0],
          };
          
          // Silently acquires an access token which is then attached to a request for Microsoft Graph data
          instance
              .acquireTokenSilent(request)
              .then((response) => {
                  SetToken(response.accessToken);
                  const config = {
                      headers: { Authorization: `Bearer ${response.accessToken}` },
                  };

                  createAPIEndpoint(ENDPOINTS.Email)
                      .fetchEmailHist(dataAsunto.noProcedimiento, dataAsunto.noAlfaNordic, dataAsunto.noLinea,config)
                      .then((respuesta) => setMailRowsHis(respuesta.data.value),setCanCallApi(false))
                      .catch((e) => {
                        setTo(".");
                        setError(e.message);
                        setOpenPopupErrorRequest(true);
                      })
                      if (to !== ".") {
                      createAPIEndpoint(ENDPOINTS.Email)
                          .postMail(MailContent,config)
                          .finally(() => {
                              setTo(".");
                              setOpenPopup(true);
                          })
                          .catch((e) => {
                            setTo(".");
                            setError(e.message);
                            setOpenPopupErrorSend(true);
                          })
                  }

              })
              
              .catch((e) => {
                setError(e.message)
                  instance.acquireTokenRedirect(request).then((response) => {
                      SetToken(response.accessToken);
                  });
              });
      }
  }, [MailContent, SendMail,open]);

    function SendMail() {
        setCanCallApi(true);
    let attach = mailAttachments;
        setTo(dataAsunto.mailTo);
        setMailContent({
            to: dataAsunto.mailTo,
            subject: `${dataAsunto.dataAsunto}|${CusomterSubject}`,
            body: MailBody.length>1?MailBody:"",
            no_AlfaNordic: dataAsunto.noAlfaNordic,
            no_Procedimiento:dataAsunto.noProcedimiento,
            no_LineaProcedimiento:dataAsunto.noLinea,
            attachments: attach,
        });
        
  }
  return (
    <Box sx={{ mt: 1.5 }}>
      <Button onClick={handleClickOpen}>
        <Typography
          color="alfaNordic.main"
          alignItems="center"
          aligntext="center"
        >
          <MailIcon sx={{ height: 30, width: 30 }} />
        </Typography>
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={false}
        maxWidth="100vh"
        fullWidth={true}
        sx={{ backgroundColor: "alfaNordic.menus" }}
      >
        <Snackbar
        open={openPopUp}
        autoHideDuration={2000}
        onClose={handleClosePopUp}
      >
        <Alert
          onClose={handleClosePopUp}
          severity="success"
          sx={{ width: "100%",backgroundColor:"alfaNordic.main"}}
        >
          Mensaje enviado correctamente!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openPopUpErrorRequest}
        autoHideDuration={10000}
        onClose={handleClosePopUp}
      >
        <Alert
          onClose={handleClosePopUp}
          severity="error"
          sx={{ width: "100%",backgroundColor:"#f44336"}}
        >
          Error: No se han podido cargar los mensajes
        </Alert>
      </Snackbar>
      <Snackbar
        open={openPopUpErrorSend}
        autoHideDuration={10000}
        onClose={handleClosePopUp}
      >
        <Alert
          onClose={handleClosePopUp}
          severity="error"
          sx={{ width: "100%",backgroundColor:"#f44336"}}
        >
          Error: No se ha enviado el mensaje. 
          {error}
        </Alert>
      </Snackbar>
        <Grid container spacing={0} style={{ minHeight: "auto" }}>
          <Grid item xs={12} md={5}>
            <DialogTitle textAlign="center">ENVIAR CORREO</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="body"
                label="identificador"
                type="text"
                fullWidth
                variant="outlined"
                defaultValue={dataAsunto.dataAsunto}
                inputProps={{ readOnly: true }}
              />
              <TextField
                autoFocus
                margin="dense"
                id="body"
                label="Asunto (Max.200)"
                type="text"
                fullWidth
                onChange={(newValue) =>
                  setCustomerSubject(newValue.target.value)
                }
                variant="outlined"
                inputProps={{ maxLength: 200 }}
              />

              <TextField
                autoFocus
                margin="dense"
                id="body"
                label="Cuerpo (Max.1500)"
                type="text"
                multiline
                fullWidth
                              minRows={7}
                              maxRows={6}
                onChange={(newValue) => setMailBody(newValue.target.value)}
                inputProps={{ maxLength: 1500 }}
                variant="outlined"
                sx={{ height: 200 }}
              />

              <AtachFiles setMailAttachments={setMailAttachments} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="alfaNordic">
                Cancelar
              </Button>
              <Button onClick={SendMail} color="alfaNordic">
                Enviar
                          </Button>
                          
            </DialogActions>
          </Grid>
          {/*Attachments table*/ }
          <Grid item xs={12} md={7}>
            <DialogTitle textAlign="center">RESUMEN DE CORREOS</DialogTitle>
            <DialogContent>
              <DataGridPro
                rows={open && typeof mailRowsHis!== "undefined" ?mailRowsHis:tempRows}
                getRowId={(row) => row.no_Linea}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20]}
                
                pagination={true}
                disableSelectionOnClick={true}
                              components={{ Toolbar: CustomToolbar }}
                              getDetailPanelContent={({ row }) => (

                                  <DetailMail ClickedRowId={row} data={mailRowsHis}/>
                              )}
                sx={{
                  height: 441,
                  border: 0,
                  p: 2,
                  borderColor: "alfaNordic.main",
                  backgroundColor: "#9a9b9966",
                  "& .header": {
                    //backgroundColor:"alfaNordic",,
                  },
                  "& .MuiDataGrid-row:hover": {
                    color: "alfaNordic.main",
                  },
                  "& .MuiDataGrid-row:selected": {
                    color: "alfaNordic.main",
                  },
                  "& .MenuItem.Mui-selected": {
                    color: "blue",
                  },
                  "& .MuiCheckbox-root svg": {
                    width: 20,
                    height: 20,
                    backgroundColor: "transparent",
                    borderRadius: 0.5,
                  },
                  "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg":
                    {
                      backgroundColor: "alfaNordic.main",
                    },
                  "& .MuiDataGrid-row.Mui-selected": {
                    color: "alfaNordic.main",
                  },
                  "& .MuiDataGrid-panelWrapper": {
                    flexDirection: "column",
                  },
                  "& .MuiTablePagination-selectLabel": {},
                }}
                getDetailPanelHeight={() => "auto"}
                pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
                              localeText={{
                                  noRowsLabel: "No hay datos para mostrar",
                  toolbarColumns: "Columnas",
                  columnsPanelTextFieldLabel: "Buscar Columna",
                  toolbarColumnsLabel: "Seleccionar Columnas",
                  columnsPanelTextFieldPlaceholder: "Título Columna",
                  columnsPanelDragIconLabel: "Reordenar Columna",
                  columnsPanelShowAllButton: "Mostrar Todo",
                  columnsPanelHideAllButton: "Ocultar Todo",
                  toolbarFilters: "Filtros",
                  filterPanelAddFilter: "Añadir Filtro",
                  filterPanelColumns: "Columnas",
                  filterPanelOperators: "Operador",
                  filterPanelOperatorAnd: "Y",
                  filterPanelOperatorOr: "O",
                  filterPanelInputLabel: "Valor",
                  filterPanelInputPlaceholder: "Valor de Filtro",
                  filterOperatorContains: "contiene",
                  filterOperatorEquals: "=",
                  filterOperatorStartsWith: "empieza con",
                  filterOperatorEndsWith: "termina con",
                  filterOperatorIs: "es",
                  filterOperatorNot: "no es",
                  filterOperatorAfter: "después de",
                  filterOperatorOnOrAfter: "esta en o después de",
                  filterOperatorBefore: "antes de",
                  filterOperatorOnOrBefore: "esta en o antes de",
                  filterOperatorIsEmpty: "esta vacio",
                  filterOperatorIsNotEmpty: "no esta vacio",
                  filterOperatorIsAnyOf: "es cualquiera de",
                  toolbarDensity: "Densidad",
                  toolbarDensityCompact: "Compacto",
                  toolbarDensityStandard: "Estandar",
                  toolbarDensityComfortable: "Confortable",
                  toolbarExport: "",
                  pinToLeft: "anclar izquierda",
                  pinToRight: "anclar derecha",
                  unpin: "desanclar",
                  columnMenuLabel: "Menu",
                  columnMenuShowColumns: "Mostrar columnas",
                  columnMenuFilter: "Filtrar",
                  columnMenuHideColumn: "Ocultar",
                  columnMenuUnsort: "Desclasificar",
                  columnMenuSortAsc: "Orden ASC",
                  columnMenuSortDesc: "Orden DES",
                  filterValueTrue: "Sí",
                  filterValueFalse: "No",
                  filterValueAny: "Cualquiera",
                  toolbarQuickFilterPlaceholder: "Buscar...",
                  footerRowSelected: (count) =>
                    count !== 1
                      ? `${count.toLocaleString()} Lineas seleccionadas`
                      : `${count.toLocaleString()} Linea seleccionada`,
                  footerTotalRows: "Total Lineas:",
                }}
                componentsProps={{
                  toolbar: {
                    //searchbar
                    showQuickFilter: true,

                    quickFilterProps: { debounceMs: 500 },
                  },
                  pagination: {
                    labelRowsPerPage: "Lineas Por Página",
                  },
                  panel: {
                    sx: {
                      "& .MuiTypography-root": {
                        color: "alfaNordic.mainTextColor",
                        fontSize: 14,
                      },
                      "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                        backgroundColor: "alfaNordic.main",
                      },
                      "& .css-ffvk8r-MuiButtonBase-root-MuiButton-root": {
                        color: "alfaNordic.main",
                      },
                    },
                  },
                }}
              />
            </DialogContent>
              </Grid>
        </Grid>
      </Dialog>
    </Box>
  );
}
