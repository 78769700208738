import React from "react";
import Chart from "react-apexcharts";
import { Card, CardContent, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";



const PieChart = ({ Title, data,type,intensity,useMonochrome,sumOfAllValues,MonochromeColor}) => {
  const theme = useTheme();
  const tempGraphData = {
    series: [1, 2, 3],
    labels: ["uno", "dos", "tres"],
  };

  

  return (
    <Card
      sx={{
        border: 0,
        borderColor: "alfaNordic.main",
        backgroundColor: "#9a9b9966",
       
      }}>
        
      <CardContent >
        <Typography variant="h5" color="widget.main" textAlign="center">
         POR {Title}
        </Typography>
        
        <Chart
          className="chart"
          type="pie"
          sx={{ "& .apexcharts-tooltip.light":{    
            background:"#000000",
            color:"#fff"
            }}}
          series={
              typeof data.series === "undefined" ? tempGraphData.series : Title === "GRAVEDAD"?[...data.series].reverse():[...data.series]
              
          }
                 
          height={250}
                  options={{

            stroke: {
              width: 0
              },
              dataLabels: {
                  enabled: true,
                  formatter: function (value) {
                    
                      return Math.round((value / 100) * sumOfAllValues)
                  },
                  
              },
            fill: {
                type: 'gradient',
               
            },
            
            theme: {
              monochrome: {

                    enabled: useMonochrome,
                    color: MonochromeColor,
                    shadeTo: useMonochrome ? type:"",
                    shadeIntensity: useMonochrome ? intensity:""
              }
            },
            labels:typeof data.labels === "undefined" ? tempGraphData.labels : data.labels,
            
              
            

            chart: {
              toolbar: {
                show: false,

              },
              zoom: {
                enabled: false,

              },
              /*events: {
                dataPointSelection: (event, chartContext, config) => {
                  console.log(config.w.config.series[config.dataPointIndex])
                  console.log(config.w.config.labels[config.dataPointIndex])
                }
              },*/
            },
          
            legend: {
              show: true,
              position: "right",
              floating: false,
              verticalAlign: "right",
              align: "right",
              width:210,
              height: "auto",
              itemMargin: { vertical: 5, horizontal:  10},
              fontSize: 13,
             
              labels: {
                colors: "text.main",
                useSeriesColors: false, 
              },
            },
                      colors: typeof data.colors === "undefined" ? ["#ff4343", "#ffc043", "#fff443"]:data.colors
                  }}
                 
        />
        
      </CardContent>
    </Card>
  );
};

export default PieChart;
