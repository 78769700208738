import * as React from "react";


import {
    DataGridPro,
  gridVisibleSortedRowIdsSelector,
  useGridApiRef,
  GridToolbarQuickFilter,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from "@mui/x-data-grid-pro";

import Grid from "@mui/material/Grid";

import ReportProblemTwoToneIcon from '@mui/icons-material/ReportProblemTwoTone';
import { useState} from "react";

import { Typography } from "@mui/material";

import DetailTable from "./DetailTable";

import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import dayjs from "dayjs";

const columns = [
  {
    field: "no_Alfa_Nordic",
    headerName: "Nº Registro",
    width: 95,
    headerClassName: "header",
    
  },
  {
    field: "tipo_expediente",
    headerName: "Tipo Expediente",
    width: 140,
    headerClassName: "header",
  },
  {
    field: "no_Expediente_actual",
    headerName: "Expediente",
    width: 110,
    headerClassName: "header",
  },
  {
    field: "nombre_titular",
    headerName: "Titular",
    width: 200,
    headerClassName: "header",
  },
  {
    field: "importe_inicial",
    headerName: "Importe",
    width: 80,
    type: "number",
    valueFormatter: (params) => {
      const valueFormatted = Number(params.value).toLocaleString();
      return `${valueFormatted} €`;
    },
    headerClassName: "header",
  },
  {
    field: "gravedad",
    headerName: "Gravedad",
    width: 110,
    headerClassName: "header",
  },
  {
    field: "organismo_inicial",
    headerName: "Organismo",
    width: 250,
    headerClassName: "header",
  },
  {
    field: "motivo_sancion",
    headerName: "Motivo",
    width: 150,
    headerClassName: "header",
  },
  {
    field: "fecha_sancion",
    headerName: "Fecha Denuncia",
    width: 120,
    type: "date",
    headerClassName: "header",
    valueFormatter: params => 
    dayjs(params?.value).format("DD/MM/YYYY"),
  },
  {
    field: "matricula",
    headerName: "Matrícula",
    width: 80,
    headerClassName: "header",
  },
  {
    field: "estado",
    headerName: "Estado (General)",
    width: 110,
    headerClassName: "header",
  },
];
const tempRows = [
  {
    No: 1,
    Document: "BOLETIN DE DENUNCIA",
    Customer_Reception: "01/01/2020",
    AlfaNordic_Reception: "05/01/2020",
    Due_Date: "15/01/2020",
    Org_Date: "01/01/2020",
    Procedure_Type_Done: "Recurso Presentado",
    State: "Presentado",
    State_Date: "02/01/2020",
  },
  {
    No: 2,
    Document: "BOLETIN DE DENUNCIA",
    Customer_Reception: "01/01/2020",
    AlfaNordic_Reception: "05/01/2020",
    Due_Date: "15/01/2020",
    Org_Date: "01/01/2020",
    Procedure_Type_Done: "Recurso Presentado",
    State: "Presentado",
    State_Date: "02/01/2020",
  },
];
const Table = ({ rowdata, filteredData, setFilteredData, onFilterChange, loading, filterModel, setFilterModel,setError,error,keywords}) => {
  const [selectedRows, setSelectedRows] = useState([]);
    const apiRef = useGridApiRef();
    /*useEffect(() => {

        setFilterModel({ items: [{ columnField: 'Requiere Acción', operatorValue: 'is', value: 'true' }] })
        sessionStorage.setItem('filters', JSON.stringify(filterModel))


    }, [setFilterModel]);*/
  
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Grid container
          spacing={3}
          
          justifyContent="space-between"
          style={{ minHeight: "auto" }}>
          <Grid item xs={0}><GridToolbarColumnsButton color="alfaNordic"/>
        <GridToolbarFilterButton color="alfaNordic" />
                    <GridToolbarDensitySelector color="alfaNordic" />
                   </Grid>
                

                <Grid item xs={0}><GridToolbarQuickFilter /></Grid>
               
        </Grid>
        
        
      
      </GridToolbarContainer>
    );
  };
  const actionColum = [
    {
      field: "Requiere Acción",
      headerName: "",
      type:'boolean',
      
      width:80,
      headerClassName: "header",
      valueGetter: (params) => {   
        if(keywords.some(keyword => params.row.estado_expediente.includes(keyword)))
        return true
      },
      renderCell: (params) => {
        
        const cellContent = () =>{
          
          
          if(keywords.some(keyword => params.row.estado_expediente.includes(keyword))){
          return(
          <>
                  <ReportProblemTwoToneIcon
                className="icon"
                      sx={{ mr: 1, color: "#ffeb3b" }}
              />
          </>
          )
          }
          return <><Typography color="transparent">no</Typography></>
        }
  
        return (
          cellContent()
          

        );
      },
    },
  ];
  
  function handleFilterChange(state){
  const newRows = gridVisibleSortedRowIdsSelector(apiRef.current.state,
  apiRef.current.instanceId)
  const dataToUse = rowdata.filter((row) => {
    return newRows.indexOf(row.no_Alfa_Nordic) != -1;
  })
  
  if(onFilterChange(dataToUse).importeTotal === filteredData.importeTotal){
      

  }
  else{
    if(selectedRows<=0){
        setFilteredData(onFilterChange(dataToUse));
        
    }
  }
 }
    const HandleFilterModelChange = (newFilterModel) => {
        setFilterModel(newFilterModel);
        sessionStorage.setItem('filters', JSON.stringify(newFilterModel))
        
    }


   
  return (
      <DataGridPro
         
          filterModel={JSON.parse(sessionStorage.getItem('filters')) !== null ? JSON.parse(sessionStorage.getItem('filters')):filterModel}
          onFilterModelChange={(newFilterModel) => HandleFilterModelChange(newFilterModel)}
      rows={rowdata}
      columns={columns.concat(actionColum)}
      getRowId={(row) => row.no_Alfa_Nordic}
      pagination={true}
      checkboxSelection
      apiRef={apiRef}
      loading={loading}
      onStateChange={(state) => { 
          handleFilterChange(state)
      }}
      selectionModel={selectedRows}
      onSelectionModelChange={(newSelectionModel) => {
        setSelectedRows(newSelectionModel);
        const newRows = gridVisibleSortedRowIdsSelector(apiRef.current.state,
        apiRef.current.instanceId)
        const dataUse = rowdata.filter((row) => {
          return newRows.indexOf(row.no_Alfa_Nordic) != -1;
        })
        const datas = rowdata.filter((row) => {
            return newSelectionModel.indexOf(row.no_Alfa_Nordic) != -1;
        })
        if (newSelectionModel.length > 0) {
          setFilteredData(onFilterChange(datas.filter((row) => {
            return newSelectionModel.indexOf(row.no_Alfa_Nordic) != -1;
          })));
          
        }
        else setFilteredData(onFilterChange(dataUse));
        
    
        
      }}
      disableMultipleSelection={false}
      components={{ Toolbar: CustomToolbar }}
      getDetailPanelContent={({ row }) => (
        
        <DetailTable ClickedRowId={row}  error={error}
        setError={setError}/>
      )}
      
      sx={{
        height: 700,
        border: 0,
        p: 4,
        borderColor: "alfaNordic.main",
        backgroundColor: "#9a9b9966",
        "& .header": {
          //backgroundColor:"alfaNordic",,
        },
        "& .MuiDataGrid-row:hover": {
          color: "alfaNordic.main",
        },
        "& .MuiDataGrid-row:selected": {
          color: "alfaNordic.main",
        },
        "& .MenuItem.Mui-selected": {
          color: "blue",
        },
        "& .MuiCheckbox-root svg": {
          width: 20,
          height: 20,
          backgroundColor: "transparent",
          borderRadius: 0.5,
        },
        "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
          backgroundColor: "alfaNordic.main",
        },
        "& .MuiDataGrid-row.Mui-selected": {
          color: "alfaNordic.main",
        },
        "& .MuiDataGrid-panelWrapper":{
          
          flexDirection:"column"
        },
        "& .MuiTablePagination-selectLabel":{
         
        }
       
      }}
      getDetailPanelHeight={() => "auto"}
      pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
      localeText={{
        noRowsLabel:"No hay datos para mostrar",
        toolbarColumns: "Columnas",
        columnsPanelTextFieldLabel: "Buscar Columna",
        toolbarColumnsLabel: "Seleccionar Columnas",
        columnsPanelTextFieldPlaceholder: "Título Columna",
        columnsPanelDragIconLabel: "Reordenar Columna",
        columnsPanelShowAllButton: "Mostrar Todo",
        columnsPanelHideAllButton: "Ocultar Todo",
        toolbarFilters: "Filtros",
        filterPanelAddFilter: "Añadir Filtro",
        filterPanelColumns: "Columnas",
        filterPanelOperators: "Operador",
        filterPanelOperatorAnd: "Y",
        filterPanelOperatorOr: "O",
        filterPanelInputLabel: "Valor",
        filterPanelInputPlaceholder: "Valor de Filtro",
        filterOperatorContains: "contiene",
        filterOperatorEquals: "=",
        filterOperatorStartsWith: "empieza con",
        filterOperatorEndsWith: "termina con",
        filterOperatorIs: "es",
        filterOperatorNot: "no es",
        filterOperatorAfter: "después de",
        filterOperatorOnOrAfter: "esta en o después de",
        filterOperatorBefore: "antes de",
        filterOperatorOnOrBefore: "esta en o antes de",
        filterOperatorIsEmpty: "esta vacio",
        filterOperatorIsNotEmpty: "no esta vacio",
        filterOperatorIsAnyOf: "es cualquiera de",
        toolbarDensity: "Densidad",
        toolbarDensityCompact: "Compacto",
        toolbarDensityStandard: "Estandar",
        toolbarDensityComfortable: "Confortable",
        toolbarExport: "",
        pinToLeft: "anclar izquierda",
        pinToRight: "anclar derecha",
        unpin: "desanclar",
        columnMenuLabel: "Menu",
        columnMenuShowColumns: "Mostrar columnas",
        columnMenuFilter: "Filtrar",
        columnMenuHideColumn: "Ocultar",
        columnMenuUnsort: "Desclasificar",
        columnMenuSortAsc: "Orden ASC",
        columnMenuSortDesc: "Orden DES",
        filterValueTrue:"Sí",
        filterValueFalse:"No",
        filterValueAny:"Cualquiera",
        toolbarQuickFilterPlaceholder:"Buscar...",
        footerRowSelected: (count) =>
          count !== 1
            ? `${count.toLocaleString()} Lineas seleccionadas`
            : `${count.toLocaleString()} Linea seleccionada`,
        footerTotalRows: "Total Lineas:",
        
      }}
      componentsProps={{
        toolbar: {
          //searchbar
          showQuickFilter: true,

          quickFilterProps: { debounceMs: 500 },
        },
        pagination:{
          labelRowsPerPage: "Lineas Por Página",
        },
        panel: {
          
          sx: {
            '& .MuiTypography-root': {
              color: "alfaNordic.mainTextColor",
              fontSize: 14,
            },
            "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
              backgroundColor: "alfaNordic.main"
            },
            '& .css-ffvk8r-MuiButtonBase-root-MuiButton-root':{
              color: "alfaNordic.main",
            },
            
          },
        },
      }}
    />
  );
};

export default Table;
