import React from "react";
import TextField from '@mui/material/TextField';


const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result);
    }
    fileReader.onerror = (error) => {
      reject(error);
    }
  })
};
const handleFileRead = (event) => {
  const files = event.target.files;
 
  let returnedFiles=[];
  for(let a = 0; a<files.length;a++){
    convertBase64(files[a]).then(res => returnedFiles.push({FileName:files[a].name,Base64String:res.substring(res.indexOf(",") + 1)}));
    }
   
  return returnedFiles;
 
}

const AtachFiles = ({setMailAttachments}) => {

  
    return (
     
        <TextField 
        type="file"
        inputProps={{ accept: 'image/*, .xlsx, .xls, .csv, .pdf, .pptx, .pptm, .ppt',multiple:'multiple' }}
        required
        name="originalFileName"
        onChange={e =>setMailAttachments(handleFileRead(e))}
        size="medium"
        
        
      />
     
    );
  };
  
  export default AtachFiles;