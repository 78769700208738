import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import Avatar from "@mui/material/Avatar";

import { useTheme } from "@mui/material/styles";

import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { msalInstance } from "../../utils/authConfig";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { ENDPOINTS, createAPIEndpoint } from "../../components/API/Api";
import { loginRequest } from "../../utils/authConfig";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import EmailIcon from "@mui/icons-material/Email";
import { typography } from "../../utils/typography";
import LinearProgress from "@mui/material/LinearProgress";

const handleLogout = () => {
  sessionStorage.clear();
  msalInstance.logoutRedirect({
    postLogoutRedirectUri: "/",
  });
};

export default function PrimarySearchAppBar({
  setFilterModel,
  customers,
  personName,
  fromDate,
  toDate,
  isAdmin,
  isFetchingData
}) {
  const theme = useTheme();
  const [dataToSend, setDataToSend] = useState({});
  const [dataToSendMail, setDataToSendMail] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [format, setFormat] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [clickedSendMail, setClickedSendMail] = useState(false);
  const open = Boolean(anchorEl);
  const { instance, inProgress, accounts } = useMsal();
  const [token, SetToken] = useState("");

  const handleClickPDF = (event) => {
    setFormat("PDF");
    setAnchorEl(event.currentTarget);
  };
  const handleClickEXCEL = (event) => {
    setFormat("EXCEL");
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const Handle_PDF_EXCEL_Click = (isDetailed) => {
    setClicked(true);
    let customersSelected = customers.filter((e) =>
      personName.includes(e.name)
    );

    setDataToSend({
      customers: customersSelected.map((no) => no.no),
      fromDate: dayjs(fromDate).format("YYYY-MM-DD"),
      toDate: dayjs(toDate).format("YYYY-MM-DD"),
      format: format,
      detailed: isDetailed,
    });
    handleClose();
  };
  const Handle_Simple_PDF_EXCEL_Click = (isDetailed) => {
    setClickedSendMail(true);
    let customersSelected = customers.filter((e) =>
      personName.includes(e.name)
    );
    setDataToSendMail({
      customer: customersSelected.map((no) => no.no)[0],
      fromDate: dayjs(fromDate).format("YYYY-MM-DD"),
      toDate: dayjs(toDate).format("YYYY-MM-DD"),
      format: format,
      detailed: isDetailed,
    });

    handleClose();
  };

  const ButtonhandleMailTo = ({ mailto,isOnlyOneCustomerSelected}) => {
    if(isOnlyOneCustomerSelected && isAdmin || !isAdmin)
    return (
      <Link
        to="#"
        onClick={(e) => {
          window.location.href = mailto;
          e.preventDefault();
        }}
      >
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="open drawer"
          sx={{ mr: 4 }}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar
            alt=""
            src={require("../../utils/outlook.png")}
            variant="rounded"
            sx={{
               
              width:  { xs: 30, md: 40 }, height:  { xs: 30, md: 40 }
            }}
          />
        </IconButton>
      </Link>
    );
  };

  const MailButtonsConditional = ({ isOnlyOneCustomerSelected }) => {
    if (isOnlyOneCustomerSelected && isAdmin)
      return (
        <>
          <MenuItem onClick={() => Handle_Simple_PDF_EXCEL_Click(false)}>
            <Typography variant="smallWidget">MAIL EXPEDIENTES </Typography>
          </MenuItem>
          <MenuItem onClick={() => Handle_Simple_PDF_EXCEL_Click(true)}>
            <Typography variant="smallWidget">
              MAIL EXPEDIENTES + PROCEDIMIENTOS{" "}
            </Typography>
          </MenuItem>
        </>
      );
  };

  function downloadPDF(Base64String) {
    const downloadLink = document.createElement("a");
    let fileName = "";
    let linkSource = "";
    if (format === "PDF") {
      fileName =
        "AlfaNordic_" +
        dayjs(fromDate).format("YYYY-MM-DD") +
        " - " +
        dayjs(toDate).format("YYYY-MM-DD") +
        ".pdf";
      linkSource = `data:application/pdf;base64,${Base64String}`;
    } else if (format === "EXCEL") {
      fileName =
        "AlfaNordic_" +
        dayjs(fromDate).format("YYYY-MM-DD") +
        " - " +
        dayjs(toDate).format("YYYY-MM-DD") +
        ".xlsx";
      linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${Base64String}`;
    }

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  useEffect(() => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data

    if (clicked) {
      instance

        .acquireTokenSilent(request)
        .then((tokenResponse) => {
          SetToken(tokenResponse.accessToken);

          const config = {
            headers: { Authorization: `Bearer ${tokenResponse.accessToken}` },
          };

          createAPIEndpoint(ENDPOINTS.Report)
            .post(dataToSend, config)
            .then((response) => downloadPDF(response.data));
        })
        //catch de token
        .catch((e) => {
          instance.acquireTokenRedirect(request).then((tokenResponse) => {
            SetToken(tokenResponse.accessToken);
          });
        });
    }
  }, [dataToSend]);

  useEffect(() => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data

    if (clickedSendMail) {
      instance

        .acquireTokenSilent(request)
        .then((tokenResponse) => {
          SetToken(tokenResponse.accessToken);

          const config = {
            headers: { Authorization: `Bearer ${tokenResponse.accessToken}` },
          };

          //cierre del then del token response
          createAPIEndpoint(ENDPOINTS.SendReports).post(dataToSendMail, config);
          //.then("mensaje ok")
        })
        //catch de token
        .catch((e) => {
          instance.acquireTokenRedirect(request).then((tokenResponse) => {
            SetToken(tokenResponse.accessToken);
          });
        });
    }
  }, [dataToSendMail]);

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: theme.palette.alfaNordic.blackColor,
          color: theme.palette.alfaNordic.mainColor,
          maxWidth: "100hv"
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
          >
            <Avatar
              alt=""
              src={require("../../utils/Logo2.png")}
              variant="rounded"
              sx={{
                width: { xs: 50, md: 100 },
                height: { xs: 50, md: 100 },
              }}
            />
          </IconButton>

          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              xs={0}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              sx={{ display: { xs: "none", md: "inline" } }}
            >
              <Typography
                variant="h2"
                color="#fff"
                style={{
                  whiteSpace: "nowrap",
                  display: "inline-block",
                  marginRight: "30px",
                }}
              >
                ALFA
              </Typography>
              <Typography
                variant="h2"
                color="alfaNordic.main"
                style={{ whiteSpace: "nowrap", display: "inline-block" }}
              >
                NORDIC
              </Typography>
            </Grid>
          </Grid>

          <ButtonhandleMailTo
            mailto={
              isAdmin === false
                ? "mailto:juridico@alfanordic.es"
                : "mailto:" +
                  customers
                    .filter((e) => personName.includes(e.name))
                    .map((cust) => cust.email)[0]
            }
            isOnlyOneCustomerSelected={
              personName.length === 1
                ? personName[0] !== "NO HAY CLIENTES SELECCIONADOS"
                  ? true
                  : false
                : false
            }
          />

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClickPDF}
          >
            <Avatar
              alt=""
              src={require("../../utils/pdf.png")}
              variant="rounded"
              sx={{
                width: { xs: 30, md: 40 },
                height: { xs: 30, md: 40 },
              }}
            />
          </IconButton>

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={handleClickEXCEL}
          >
            <Avatar
              alt=""
              src={require("../../utils/xcel.png")}
              variant="rounded"
              sx={{
                width: { xs: 40, md: 50 },
                height: { xs: 40, md: 50 },
              }}
            />
          </IconButton>

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={() => {
              handleLogout();
            }}
          >
            <LogoutIcon
              sx={{
                width: { xs: 40, md: 50 },
                height: { xs: 40, md: 50 },
              }}
              color="alfaNordic"
            />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => Handle_PDF_EXCEL_Click(false)}>
          <Typography variant="smallWidget"> DESCARGAR EXPEDIENTES</Typography>
        </MenuItem>
        <MenuItem onClick={() => Handle_PDF_EXCEL_Click(true)}>
          <Typography variant="smallWidget">
            DESCARGAR EXPEDIENTES + PROCEDIMIENTOS
          </Typography>
        </MenuItem>
        <MailButtonsConditional
          isOnlyOneCustomerSelected={
            personName.length === 1
              ? personName[0] !== "NO HAY CLIENTES SELECCIONADOS"
                ? true
                : false
              : false
          }
        />
      </Menu>
    {isFetchingData && (<LinearProgress color="alfaNordic" />)}
    </>
  );
}
