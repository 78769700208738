import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./utils/authConfig";
import { CssBaseline } from '@mui/material';

export const msalInstance = new PublicClientApplication(msalConfig);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
        <MsalProvider instance={msalInstance}>
          <CssBaseline/>
            <App />
        </MsalProvider>
  </React.StrictMode>
);

