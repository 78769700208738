import React from "react";
import PieChart from "../../components/PieChart/PieChart";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";


const PieCharts = ({ DataTipo, DataMot,DataGra}) => {
  
  const colorsTypeChart = "#4CAF50"
  const colorsMotvChart = "#008FFB"
  const colorsGraChart = ["#ff4343", "#ffc043", "#fff443"]

  const sortObject = (obj) => {
    const labelsOrder = ['Muy Grave', 'Grave', 'Leve']
    obj.colors.sort((a, b) => labelsOrder.indexOf(obj.labels[obj.colors.indexOf(a)]) - labelsOrder.indexOf(obj.labels[obj.colors.indexOf(b)]));
    obj.labels.sort((a, b) => labelsOrder.indexOf(a) - labelsOrder.indexOf(b));
    obj.series.sort((a, b) => labelsOrder.indexOf(obj.labels[obj.series.indexOf(a)]) - labelsOrder.indexOf(obj.labels[obj.series.indexOf(b)]));

    
    
    
    return obj;
  }

 
  
  if (typeof DataMot !== "undefined") 
  return (
     
    <Box sx={{pt:4}}>
    <Grid container spacing={2}  justifyContent="space-between">
              <Grid item xs={12} lg={4} ><PieChart data={DataTipo} sumOfAllValues={DataTipo.series.reduce((partialSum, a) => partialSum + a, 0)} useMonochrome={true} Title="TIPO" MonochromeColor={colorsTypeChart} type="dark" intensity={0.5}/></Grid>
              <Grid item xs={12} lg={4} ><PieChart data={DataMot} sumOfAllValues={DataMot.series.reduce((partialSum, a) => partialSum + a, 0)} useMonochrome={true} Title="MOTIVO" MonochromeColor={colorsMotvChart} type="light" intensity={0.5}/></Grid>
              <Grid item xs={12} lg={4} ><PieChart data={sortObject(DataGra)} sumOfAllValues={DataGra.series.reduce((partialSum, a) => partialSum + a, 0) } useMonochrome={false} Title="GRAVEDAD"  type="light" intensity={0.5}/></Grid>  
    </Grid>
    </Box>
   
  );
};

export default PieCharts;

