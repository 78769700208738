import * as React from "react";

import {
  DataGridPro,
 
} from "@mui/x-data-grid-pro";

import { useState, useEffect } from "react";
import { ENDPOINTS, createAPIEndpoint } from "../API/Api";

import { useTheme } from "@mui/material/styles";
import { Container } from "@mui/system";
import Mail from "../../components/AtachFiles/Mail";

import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import { loginRequest } from "../../utils/authConfig";
import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const columns = [
  {
    field: "no_Procedimiento",
    headerName: "Nº Procedimiento",
    flex: 1,
    headerClassName: "header",
  },
  {
    field: "descripcion",
    headerName: "Documento",
    flex: 1,
    headerClassName: "header",
  },
  {
    field: "Fecha_recepcion",
    headerName: "Recepción Cliente",
    flex: 1,
    type: "date",
    headerClassName: "header",
    valueFormatter: (params) => dayjs(params?.value).format("DD/MM/YYYY"),
  },
  {
    field: "fecha_Alfa_Nordic",
    headerName: "Recepción Alfa Nordic",
    width: 200,
    type: "date",
    headerClassName: "header",
    valueFormatter: (params) => dayjs(params?.value).format("DD/MM/YYYY"),
  },
  {
    field: "Fecha_FINE",
    headerName: "Fecha Limite",
    flex: 1,
    type: "date",
    headerClassName: "header",
    valueFormatter: (params) => dayjs(params?.value).format("DD/MM/YYYY"),
  },
  {
    field: "fecha_organismo",
    headerName: "Fecha Organismo",
    flex: 1,
    type: "date",
    headerClassName: "header",
    valueFormatter: (params) => dayjs(params?.value).format("DD/MM/YYYY"),
  },
  {
    field: "tipo_de_recurso",
    headerName: "Tipo de trámite realizado ",
    width: 230,
    headerClassName: "header",
  },
  {
    field: "documentos_requeridos",
    headerName: "Documento requerido",
    width: 230,
    headerClassName: "header",
  },
  {
    field: "estado",
    headerName: "Estado",
    flex: 1,
    type: "date",
    headerClassName: "header",
  },
  
];
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const ProcedureTable = ({ id, c_cliente, mailToAdress }) => {
  const theme = useTheme();
  const { instance, inProgress, accounts } = useMsal();
  const [loading, setLoading] = useState(false);
  const [token, SetToken] = useState("");
  let queryParam = id;
  const [ListaProcedures, SetListaProcedures] = useState([]);
  const [openPopupError,setOpenPopupError] = useState(false);
  const [error,setError] = useState(false)
  
  const handleClosePopUp = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    
    setOpenPopupError(false)
    
  };
  useEffect(() => {
    setLoading(true);
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        SetToken(response.accessToken);
        const config = {
          headers: { Authorization: `Bearer ${response.accessToken}` },
        };
        createAPIEndpoint(ENDPOINTS.Procedures)
          .fetchById(queryParam,config)
          .then((respuesta) => {
            SetListaProcedures(respuesta.data.value);
            
          })
          .finally(()=>setLoading(false))
          .catch((e) => {
            setError(e.message)
            setOpenPopupError(true)
          })
        
      })
      
      .catch((e) => {
        setError(e.message)
        setOpenPopupError(true)
        instance.acquireTokenRedirect(request).then((response) => {
          SetToken(response.accessToken);
        });
      });
  }, []);

  const actionColum = [
    {
      field: "Action",
      headerName: "",

      width: 80,
      headerClassName: "header",
      renderCell: (params) => {
        

        const Render = () => {
          let Asunto =
            c_cliente +
            "/" +
            params.row.no_Alfa_Nordic +
            "/" +
                params.row.no_Procedimiento;
            let mailReciverAddress = mailToAdress;

          //if(params.row.estado === "Pendiente recepción documento" || params.row.estado === "Solicitud confirmación"){
          return (
              <>           
                 
                  <Mail dataAsunto={Asunto} mailTo={mailReciverAddress} noAlfaNordic={params.row.no_Alfa_Nordic} noProcedimiento={params.row.no_Procedimiento} noLinea={params.row.no_Linea}/>  
                  
            </>
          );
          //}
        };

        return Render();
      },
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton color="alfaNordic" />
        <GridToolbarFilterButton color="alfaNordic" />
        <GridToolbarDensitySelector color="alfaNordic" />
      </GridToolbarContainer>
    );
  }

  return (
    
    
    <Container className="detailTable" maxWidth={false}>
      <Snackbar
        open={openPopupError}
        autoHideDuration={10000}
        onClose={handleClosePopUp}
      >
        <Alert
          onClose={handleClosePopUp}
          severity="error"
          sx={{ width: "100%",backgroundColor:"#f44336"}}
        >
          Error: No se han podido cargar los datos.{error}
        </Alert>
      </Snackbar>
      <DataGridPro
        density="compact"
        pagination={true}
        disableMultipleSelection={false}
        components={{ Toolbar: CustomToolbar }}
        columns={columns.concat(actionColum)}
        getRowId={(ListaProcedures) => ListaProcedures.no_Procedimiento}
        rows={ListaProcedures}
        loading={loading}
        sx={{
          height: 350,
          border: 0,
          p: 2,
          mt: 3,
          borderColor: theme.palette.alfaNordic.main,
          backgroundColor: "#9a9b9966",
          "& .header": {
            backgroundColor: theme.palette.alfaNordic.mainColor,
          },
        }}
        localeText={{
          noRowsLabel:"No hay datos para mostrar",
          toolbarColumns: "Columnas",
          columnsPanelTextFieldLabel: "Buscar Columna",
          toolbarColumnsLabel: "Seleccionar Columnas",
          columnsPanelTextFieldPlaceholder: "Título Columna",
          columnsPanelDragIconLabel: "Reordenar Columna",
          columnsPanelShowAllButton: "Mostrar Todo",
          columnsPanelHideAllButton: "Ocultar Todo",
          toolbarFilters: "Filtros",
          filterPanelAddFilter: "Añadir Filtro",
          filterPanelColumns: "Columnas",
          filterPanelOperators: "Operador",
          filterPanelOperatorAnd: "Y",
          filterPanelOperatorOr: "O",
          filterPanelInputLabel: "Valor",
          filterPanelInputPlaceholder: "Valor de Filtro",
          filterOperatorContains: "contiene",
          filterOperatorEquals: "=",
          filterOperatorStartsWith: "empieza con",
          filterOperatorEndsWith: "termina con",
          filterOperatorIs: "es",
          filterOperatorNot: "no es",
          filterOperatorAfter: "después de",
          filterOperatorOnOrAfter: "esta en o después de",
          filterOperatorBefore: "antes de",
          filterOperatorOnOrBefore: "esta en o antes de",
          filterOperatorIsEmpty: "esta vacio",
          filterOperatorIsNotEmpty: "no esta vacio",
          filterOperatorIsAnyOf: "es cualquiera de",
          toolbarDensity: "Densidad",
          toolbarDensityCompact: "Compacto",
          toolbarDensityStandard: "Estandar",
          toolbarDensityComfortable: "Confortable",
          toolbarExport: "Exportar",
          pinToLeft: "anclar izquierda",
          pinToRight: "anclar derecha",
          unpin: "desanclar",
          columnMenuLabel: "Menu",
          columnMenuShowColumns: "Mostrar columnas",
          columnMenuFilter: "Filtrar",
          columnMenuHideColumn: "Ocultar",
          columnMenuUnsort: "Desclasificar",
          columnMenuSortAsc: "Orden ASC",
          columnMenuSortDesc: "Orden DES",
          footerRowSelected: (count) =>
            count !== 1
              ? `${count.toLocaleString()} Lineas seleccionadas`
              : `${count.toLocaleString()} Linea seleccionada`,
          footerTotalRows: "Total Lineas:",
          labelRowsPerPage: "Lineas Por Página",
        }}
        componentsProps={{
          toolbar: {
            //searchbar
            showQuickFilter: true,

            quickFilterProps: { debounceMs: 500 },
          },
          pagination: {
            labelRowsPerPage: "Lineas Por Página",
          },
          panel: {
            sx: {
              "& .MuiTypography-root": {
                color: "alfaNordic.mainTextColor",
                fontSize: 14,
              },
              "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                backgroundColor: "alfaNordic.main",
              },
              "& .css-ffvk8r-MuiButtonBase-root-MuiButton-root": {
                color: "alfaNordic.main",
              },
            },
          },
        }}
      />
    </Container>
    
  );
};

export default ProcedureTable;
