import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_API_URL;

export const ENDPOINTS = {
    Files:'Files',
    Procedures:'Procedures', 
    Test:"Test",
    Email:"Email",
    CustomerList:"CustomerList",
    Report:"Reports",
    SendReports:"SendReports"
}

export const createAPIEndpoint = (endpoint) => {
    
    const url = BASE_URL + endpoint;
   
    return{
        fetch: () => axios.get(url),
        fetchEmailHist: (n_pr, no_al, n_li, config) => axios.get(url + "?no_proc=" +   n_pr  + "&no_alfaN=" + no_al  + "&no_lineaProc="  + n_li ,config),
        fetchCustomers:(config) => axios.get(url,config),
        fetchById: (id, config) => axios.get(url + '?id=' + id, config),
        post: (newRecord, config) => axios.post(url, newRecord, config),
        postMail: (newRecord, config) => axios.post(url, newRecord, config),
        put:(id,updateRecord) => axios.put(url + id,updateRecord),
        deletee:id => axios.delete(url + id)
    }
        
    
}