import React from "react";
import { useState,useEffect,useRef } from "react";
import Grid from "@mui/material/Grid";

import Box from "@mui/material/Box";
import {TextField, Typography,IconButton } from "@mui/material";

import Checkbox from "@mui/material/Checkbox";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { esES } from "dayjs/locale/es";
import DayJsUtils from "@date-io/dayjs";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from '@mui/icons-material/Close';
import dayjs from "dayjs";
import LinearProgress from "@mui/material/LinearProgress";



const icon = <CheckBoxOutlineBlankIcon color="alfaNordic"/>;
const checkedIcon = <CheckBoxIcon  color="alfaNordic"/>;


const UserTopInfo = ({
  personName,
  CustomersObj,
  setCustomersObj,
  isFetchingData,
  newCustomersObj,
  setNewCustomersObj,
  setPersonName,
  customersObj,
  MenuProps,
  customers,
  userName,
  comercialName,
  fromValue,
  setFromValue,
  toValue,
  setToValue,
  setApiData,
  setDataToShow,
  setWidgetsData,
  getWidgetsValues,
  listaExpedientes,
}) => {
 const [checkBoxSelected,setCheckBoxSelected] = useState(personName.length === 400?true:false)



 

function filterObject(obj, value) {
 
  let MySelected = value.filter((personName) => {
    return personName !== "NO HAY CLIENTES SELECCIONADOS";
  });
  setApiData("");
  setCustomersObj(obj.filter((e) =>MySelected.includes(e.name)));
  sessionStorage.setItem(
    "customersSelected",
    JSON.stringify(
      obj.filter((e) =>MySelected.includes(e.name))
    )
  );
}


  const handleCustomerObject = (event, value) => {
    
    setCheckBoxSelected(false)
    if (value.length >= 1) {
      setPersonName(
        value.filter((personName) => {
          return personName !== "NO HAY CLIENTES SELECCIONADOS";
        })
      );   
      filterObject(customersObj,value)
    } else {
      setPersonName(["NO HAY CLIENTES SELECCIONADOS"]);
      sessionStorage.setItem(
        "customersSelected",
        JSON.stringify(["NO HAY CLIENTES SELECCIONADOS"])
      );
    }
  };

  const handleCustomerApiCall = () => {
    setApiData(".");
    setNewCustomersObj(JSON.parse(sessionStorage.getItem("customersSelected"))[0] === "NO HAY CLIENTES SELECCIONADOS"?null:CustomersObj)
  }


  const GetGestor_Comercial= (type) => {
    let CustomersSelected = customersObj.filter((e) =>
    personName.includes(e.name));
    
    if(type === "juridico"){
      let AllCustomersGestor = Array.from(new Set(CustomersSelected.map((cust) => cust.juridico)));
      if(AllCustomersGestor.length > 1){
        return ("VARIOS")
      }else{
        return ( AllCustomersGestor[0])
      }
     
    }
    if(type === "comercial"){
      let AllCustomerComercial = Array.from(new Set(CustomersSelected.map((cust) => cust.comercial)));
      if(AllCustomerComercial.length > 1){
        return ("VARIOS")
      }else{
        return ( AllCustomerComercial[0])
      }
    }
  }
  

  const handleFromValueDateChange = (value) => {
   
    setApiData(".");
    setFromValue(value);
    sessionStorage.setItem(
      "fromDate",
      JSON.stringify(value)
    );
  };
  const handleToValueDateChange = (value) => {
    
    setApiData(".");
    setToValue(value);
    sessionStorage.setItem(
      "toDate",
      JSON.stringify(value)
    );
  };

  const handleSelectAllCustomers = () => {
    setCheckBoxSelected(true)
    setApiData(".");
    setPersonName(customers.slice(0, 400));
    setNewCustomersObj(customersObj.slice(0, 400));
   
    sessionStorage.setItem(
      "customersSelected",
      JSON.stringify(customersObj.slice(0, 400))
    );
   
  };
  const handleDeselectAllCustomers = () => {
    setCheckBoxSelected(false)
    setApiData(".");
    setPersonName(["NO HAY CLIENTES SELECCIONADOS"]);
    setNewCustomersObj(null);
    
    sessionStorage.setItem(
      "customersSelected",
      JSON.stringify(["NO HAY CLIENTES SELECCIONADOS"])
    );
    
  };


  const HandleSelect_Deselect_All = () =>{
    if(!checkBoxSelected){
      handleSelectAllCustomers()
    }else{
      handleDeselectAllCustomers()
    }
  }
  
  const HandleFromDateRenderValue = () => {
    if(JSON.parse(sessionStorage.getItem(
      "fromDate"
    )) !== null){
      return JSON.parse(sessionStorage.getItem(
      "fromDate"
    ))}else{
      return fromValue
    }
  };
  const HandleToDateRenderValue = () => {
    if(JSON.parse(sessionStorage.getItem(
      "toDate"
    )) !== null){
      return JSON.parse(sessionStorage.getItem(
      "toDate"
    ))}else{
      return toValue
    }
  };

  const ValueToRender = (selected) => {
    if (selected.length > 1) {
      return selected[0] + "  +  " + (selected.length - 1);
    } else {
      return selected;
    }
  };
 
 

 
  return (
    <>
     
    <Grid
      container
      spacing={0}
      justifyContent="space-between"
      style={{ minHeight: "auto" }}
    >
      
      <Grid item xs={12} md={6}>
        <Box
          sx={{ mt: 2 }}
          display="flex"
          alignItems="baseline"
          textDecoration="underline"
        >
          <Typography
            mt={4}
            className="title"
            variant="h6"
            color="alfaNordic.main"
          >
            EMPRESA:
          </Typography>

          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={customers}
            limitTags={1}
            clearText={"NO HAY CLIENTES SELECCIONADOS"}
            defaultValue={personName || null}
            value={personName || null}
            isOptionEqualToValue={(option, value) => option === value}
            onChange={(event, newValue) => {
             
             if(newValue.length < 401)
                handleCustomerObject(event, newValue);
                
            }}
            onClose={() => handleCustomerApiCall()}
            
            renderTags={(selected) => (
              <Typography className="info" variant="h6" color="text.primary"  sx={{ ml: { xs: 1,md:1} }}>
                {ValueToRender(selected)}
              </Typography>
            )}
            disableCloseOnSelect
            //menu
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            style={{ width: "auto",height:"auto" }}
            renderInput={(params) => (
              <TextField {...params} 
              InputProps={{...params.InputProps, 
                endAdornment: <><IconButton onClick={handleDeselectAllCustomers}><CloseIcon color="alfaNordic"  sx={{width:50,heigth:50,mr:{xs:-3,sm:-3,md:-3,lg:0},mt:{xs:-14,sm:0,md:-8.5,lg:0}}}/></IconButton><Checkbox
                icon={icon}
                checkedIcon={checkedIcon}    
                onClick={() => HandleSelect_Deselect_All()}
                checked={checkBoxSelected}
                sx={{width:50,heigth:50,mr:-7,mt:{xs:-14,sm:0,md:-8.5,lg:0}}}
                
                
              /></>}}
              sx={{ mt: -2.3,ml:1}} />
            )}
          />
        </Box>

        <Box
          className="item"
          style={{ display: "flex", alignItems: "baseline" }}
        >
          <Typography className="title" variant="h6" color="alfaNordic.main">
            GESTOR:
          </Typography>
          <Typography
                  className="info"
                  variant="h6"
                  color="text.primary"
                  sx={{ ml: { xs: 1,md:4.7} }}
                >
                  {GetGestor_Comercial("juridico")}
                </Typography>
        </Box>

        <Box
          className="item"
          style={{ display: "flex", alignItems: "baseline" }}
        >
          <Typography className="title" variant="h6" color="alfaNordic.main">
            COMERCIAL:
          </Typography>
          <Typography
                  className="info"
                  variant="h6"
                  color="text.primary"
                  sx={{ ml: { xs: 1} }}
                >
                  {GetGestor_Comercial("comercial")}
                </Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ mt: { xs: 4, md: 6 } }}></Box>
        <Grid
          container
          spacing={0}
          mt={2}
          justifyItems="center"
          justifyContent="center"
          style={{ minHeight: "auto" }}
        >
          <Grid item xs={12} md={4}>
            <LocalizationProvider
              utils={DayJsUtils}
              locale={esES}
              dateAdapter={AdapterDayjs}
            >
              <MobileDatePicker
                inputFormat="DD/MM/YYYY"
                label={<Typography variant="h4">Desde</Typography>}
                value={HandleFromDateRenderValue()}
                closeOnSelect={true}
                componentsProps={{
                  actionBar: {
                    // The actions will be the same between desktop and mobile
                    actions: ["", ""],
                  },
                }}
                onChange={(newValue) => {
                  handleFromValueDateChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      svg: "red",
                      input: "yellow",
                      label: "purple",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <LocalizationProvider
              utils={DayJsUtils}
              locale={esES}
              dateAdapter={AdapterDayjs}
            >
              <MobileDatePicker
                inputFormat="DD/MM/YYYY"
                label={<Typography variant="h4">Hasta</Typography>}
                value={HandleToDateRenderValue()}
                closeOnSelect={true}
                componentsProps={{
                  actionBar: {
                    // The actions will be the same between desktop and mobile
                    actions: ["cancelar", "OK"],
                  },
                }}
                onChange={(newValue) => {
                  handleToValueDateChange(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
    </Grid>    
    </>
  );
};
export default UserTopInfo;
